import React, { useContext } from "react";

import { ContentTemplate } from "../atoms";
import { UsersTable } from "../molecules";
import { useUsers } from "../hooks/useUsers";
import { AuthContext } from "../context/auth-context";

const Users = () => {
  const auth = useContext(AuthContext);
  const { token } = auth;
  const { users, error: userError, isLoading } = useUsers(token);

  return (
    <ContentTemplate label="Users" isLoading={isLoading} error={userError}>
      <UsersTable data={users} />
    </ContentTemplate>
  );
};

export { Users };
