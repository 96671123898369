import React, { useContext, useState } from "react";
import { default as MDialog } from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

import { Button, LocationPicker } from "../atoms";
import useResource from "../hooks/useResource";
import { AuthContext } from "../context/auth-context";
import { useUsers } from "../hooks/useUsers";

const DeviceForm = ({ open, item = {}, handleClose, edit = false }) => {
  const auth = useContext(AuthContext);
  const { token } = auth;
  const { users, mutate } = useUsers(token);
  const [addDevice, isLoading, error, clearError] = useResource();
  const [phone, setPhone] = useState(null);
  const [location, setLocation] = useState({ lat: "", lng: "" });

  const onConfirm = async () => {
    try {
      if (edit) {
        await addDevice(
          process.env.REACT_APP_API_URL + `/users/${item.id}/device`,
          {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: `Bearer ${token}`,
          },
          "PUT",
          { phone: `+${phone}`, lat: location.lat, lng: location.lng }
        );
      } else {
        await addDevice(
          process.env.REACT_APP_API_URL + `/users/${item.id}/devices`,
          {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: `Bearer ${token}`,
          },
          "POST",
          { phone: `+${phone}`, lat: location.lat, lng: location.lng }
        );
      }
      const user = users.find((element) => element.id === item.id);
      mutate([
        ...users,
        ((user.phone = phone),
        (user.lng = location.lng),
        (user.lat = location.lat),
        (user.key = phone)),
      ]);
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };

  const handleBtnClose = () => {
    handleClose();
    clearError();
    setPhone(null);
    setLocation({ lat: "", lng: "" });
  };

  const handleChange = (number) => {
    setPhone(number);
  };

  function handleChangeLocation(lat, lng) {
    setLocation({ lat, lng });
  }

  return (
    <MDialog
      onClose={handleClose}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{edit ? "Edit Device" : "Add Device"}</DialogTitle>
      {error && (
        <Typography
          variant="subtitle2"
          align="center"
          sx={{
            color: "red",
          }}
        >
          Error: {JSON.stringify(error)}
        </Typography>
      )}
      <DialogContent>
        <Stack
          component="form"
          sx={{
            p: 1,
          }}
          spacing={2}
          autoComplete="off"
        >
          <PhoneInput
            value={phone}
            onChange={handleChange}
            country={"co"}
            preferredCountries={["co", "us"]}
            placeholder={edit ? item.phone : null}
          />
          <LocationPicker
            location={location}
            handleChange={handleChangeLocation}
            data={edit ? item.location : null}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              marginTop: "1.5rem",
            }}
          >
            <Button label="cancel" action={handleBtnClose} variant="text" />
            <Button
              label="Create"
              variant="text"
              isLoading={isLoading}
              action={onConfirm}
              disabled={!phone || phone?.length < 12 || !location.lng}
            />
          </Box>
        </Stack>
      </DialogContent>
    </MDialog>
  );
};

export { DeviceForm };
