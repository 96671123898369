import React, { createContext } from "react";

import { useAuth } from "../hooks/useAuth";

export const AuthContext = createContext({
  userData: null,
  token: null,
  login: () => {},
  logout: () => {},
});

export const AuthContextProvider = ({ children }) => {
  const { userData, token, login, logout } = useAuth();

  return (
    <AuthContext.Provider
      value={{
        userData,
        token,
        isAdmin: userData.is_admin,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
