import React from "react";

import { Tabs } from "../organisms";
import { ListsContextProvider } from "../context/lists-context";

const WhiteList = () => {
  return (
    <ListsContextProvider>
      <Tabs />
    </ListsContextProvider>
  );
};

export { WhiteList };
