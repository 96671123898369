import React, { useState, useRef, useContext } from "react";
import { default as MDialog } from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { Button } from "../atoms";
import useResource from "../hooks/useResource";
import { AuthContext } from "../context/auth-context";

const SubjectForm = ({ open, handleClose, list, mutate }) => {
  const [name, setName] = useState("");
  const [base64Image, setBase64Image] = useState("");
  const [addFace, isLoading, error, clearError] = useResource();
  const fileInputRef = useRef(null);
  const auth = useContext(AuthContext);
  const { token } = auth;

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      const base64Image = event.target.result;
      setBase64Image(base64Image);
    };
    fileReader.readAsDataURL(file);
  };

  const onConfirm = async () => {
    clearError();
    try {
      await addFace(
        process.env.REACT_APP_API_URL + "/lists/faces",
        {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`,
        },
        "POST",
        { face_id: name, list_name: list, image: base64Image }
      );
      mutate(name);
      handleClose();
    } catch (err) {
      console.log(err);
    }
    setName("");
    setBase64Image("");
    if (fileInputRef.current) fileInputRef.current.value = null;
  };

  const handleBtnClose = () => {
    handleClose();
    setName("");
    setBase64Image("");
    clearError();
    if (fileInputRef.current) fileInputRef.current.value = null;
  };

  return (
    <MDialog
      onClose={handleClose}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>Add subject</DialogTitle>
      {error && (
        <Typography
          variant="subtitle2"
          align="center"
          sx={{
            color: "red",
          }}
        >
          Error {error.details[0].msg}.
        </Typography>
      )}
      <DialogContent>
        <Stack
          component="form"
          sx={{
            width: "50ch",
            p: 1,
          }}
          spacing={2}
          autoComplete="off"
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Avatar sx={{ width: 200, height: 200 }} src={base64Image}>
              {base64Image ? null : " "}
            </Avatar>
          </Box>
          <TextField
            name="file"
            id="image-input"
            type="file"
            required
            inputProps={{ accept: "image/png, image/jpeg" }}
            onChange={handleImageUpload}
          />
          <TextField
            name="name"
            id="name-input"
            label="Name"
            type="text"
            value={name}
            onChange={(event) => {
              setName(event.target.value);
            }}
            required
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button label="cancel" action={handleBtnClose} variant="text" />
        <Button
          action={onConfirm}
          disabled={!name || !base64Image}
          label="Create"
          variant="text"
          isLoading={isLoading}
        />
      </DialogActions>
    </MDialog>
  );
};

export { SubjectForm };
