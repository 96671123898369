import React from "react";
import Typography from "@mui/material/Typography";

import { PageTemplate } from "../atoms";

const NotFound = () => {
  return (
    <PageTemplate center>
      <Typography variant="h4" gutterBottom>
        404 - Not Found
      </Typography>
    </PageTemplate>
  );
};

export { NotFound };
