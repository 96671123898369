import React, { useContext } from "react";
import PropTypes from "prop-types";
import { default as MTabs } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ContentTemplate } from "../atoms";

import { ListsTable, FacesTable } from "../molecules";
import { ListsContext } from "../context/lists-context";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const Tabs = () => {
  const ListsCtxt = useContext(ListsContext);
  const { error, isLoading, lists } = ListsCtxt;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ContentTemplate label="Lists" isLoading={isLoading}>
      {error ? (
        <Typography
          variant="h6"
          mt={2}
          align="center"
          sx={{
            color: "red",
          }}
        >
          Error: {error}
        </Typography>
      ) : (
        <>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <MTabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab label="Index" {...a11yProps(0)} />
              {!!lists &&
                lists.map((item, index) => {
                  return (
                    <Tab key={index} label={item} {...a11yProps(index + 1)} />
                  );
                })}
            </MTabs>
          </Box>
          <TabPanel value={value} index={0}>
            <ListsTable data={lists} />
          </TabPanel>
          {!!lists &&
            lists.map((item, index) => {
              return (
                <TabPanel key={item} value={value} index={index + 1}>
                  <FacesTable name={item} />
                </TabPanel>
              );
            })}
        </>
      )}
    </ContentTemplate>
  );
};

export { Tabs };
