import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { default as MSelect } from "@mui/material/Select";

const Select = ({ label = "", data = [], action, value }) => {
  return (
    <Box sx={{ maxWidth: 200 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <MSelect
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={label}
          onChange={action}
        >
          {data.map((item) => {
            return (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            );
          })}
          <MenuItem value={"all"}>Search in all lists</MenuItem>
        </MSelect>
      </FormControl>
    </Box>
  );
};

export { Select };
