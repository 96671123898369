import React from "react";
import Chart from "react-apexcharts";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

const OPTIONS = {
  plotOptions: {
    bar: {
      horizontal: true,
      barHeight: "50%",
      rangeBarGroupRows: true,
    },
  },
  fill: {
    type: "solid",
  },
  xaxis: {
    type: "datetime",
  },
  legend: {
    position: "right",
    onItemClick: {
      toggleDataSeries: false,
    },
  },
};

const TimeLineChart = ({ data }) => {
  return (
    <Paper
      elevation={3}
      sx={{
        padding: 2,
        width: "1040px",
        height: "440px",
      }}
    >
      {!!data ? (
        <Chart
          options={OPTIONS}
          series={data}
          width={1000}
          height={400}
          type="rangeBar"
        />
      ) : (
        <Typography variant="h6" align="center" sx={{ marginTop: 20 }}>
          Select user and click on "Generate Report"
        </Typography>
      )}
    </Paper>
  );
};

export { TimeLineChart };
