import React, { useContext } from "react";

import { ContentTemplate } from "../atoms";
import { EventsTable } from "../molecules";
import { useEvents } from "../hooks/useEvents";
import { AuthContext } from "../context/auth-context";

const Events = () => {
  const auth = useContext(AuthContext);
  const { token } = auth;
  const { events, isLoading, error } = useEvents(token);

  return (
    <ContentTemplate label="Events" isLoading={isLoading} error={error}>
      <EventsTable data={events ?? []} />
    </ContentTemplate>
  );
};

export { Events };
