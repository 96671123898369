import React from "react";
import { default as MDialog } from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";
import { Button } from "../atoms";

const Confirmation = ({
  open,
  action,
  handleClose,
  title,
  text,
  error,
  isLoading,
}) => {
  return (
    <MDialog
      onClose={handleClose}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{title}</DialogTitle>
      {error && (
        <Typography
          variant="subtitle2"
          align="center"
          sx={{
            color: "red",
          }}
        >
          Error: {error.message}.
        </Typography>
      )}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button label="cancel" action={handleClose} variant="text" />
        <Button
          action={action}
          label="Confirm"
          variant="text"
          isLoading={isLoading}
        />
      </DialogActions>
    </MDialog>
  );
};

export { Confirmation };
