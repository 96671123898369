import React, { useContext } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";

import useFormData from "../hooks/useFormData";
import { AuthContext } from "../context/auth-context";
import { Button } from "../atoms";

const LoginForm = () => {
  const [loginReq, isLoading, error, clearError] = useFormData();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();
    clearError();
    try {
      const response = await loginReq(
        process.env.REACT_APP_API_URL + "/auth/token",
        e.target
      );
      let { access_token, access_token_expiration_time, ...userData } =
        response;
      auth.login(userData, access_token, access_token_expiration_time);
      navigate("/home");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: 2,
        }}
      >
        <Typography variant="h5" gutterBottom>
          Sign in
        </Typography>
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            "& .MuiTextField-root": { margin: "10px 25px" },
          }}
          onSubmit={onSubmit}
        >
          <TextField
            required
            id="outlined-email-input"
            label="Username"
            name="username"
          />
          <TextField
            required
            id="outlined-password-input"
            label="Password"
            type="password"
            name="password"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "10px 25px",
            }}
          >
            <Button isLoading={isLoading} label="SIGN IN" type="submit" />
          </div>
        </Box>
      </Paper>
      {error && (
        <Typography
          variant="h6"
          mt={2}
          align="center"
          sx={{
            color: "red",
          }}
        >
          Error. Check your credentials.
        </Typography>
      )}
    </Box>
  );
};

export { LoginForm };
