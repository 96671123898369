import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

const PageTemplate = ({ children, center }) => {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth={false} disableGutters={true}>
        <Box
          sx={{
            bgcolor: "whitesmoke",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: center && "center",
            alignItems: center && "center",
          }}
        >
          {children}
        </Box>
      </Container>
    </React.Fragment>
  );
};

export { PageTemplate };
