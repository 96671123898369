export const adminPages = [
  {
    name: "Users",
    icon: "people",
    path: "/users",
  },
  {
    name: "Lists",
    icon: "listalticon",
    path: "/lists",
  },
  {
    name: "Tasks",
    icon: "assignment",
    path: "/tasks",
  },
  {
    name: "Analytics",
    icon: "analytics",
    path: "/analytics",
  },
  {
    name: "Demo",
    icon: "science",
    path: "/demo",
  },
];

export const userPages = [
  {
    name: "Home",
    icon: "home",
    path: "/home",
  },
  {
    name: "Settings",
    icon: "settings",
    path: "/settings",
  },
  {
    name: "Events",
    icon: "star",
    path: "/events",
  },
  {
    name: "Alerts",
    icon: "notificationsactive",
    path: "/alerts",
  },
];
