import React, { useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import {
  Home,
  Login,
  Landing,
  NotFound,
  Settings,
  WhiteList,
  Tasks,
  Alerts,
  Analytics,
  Demo,
  Events,
  Users,
} from "./pages";
import { AuthContext } from "./context/auth-context";

const App = () => {
  const auth = useContext(AuthContext);
  const { token, userData } = auth;

  return (
    <BrowserRouter>
      <Routes>
        {token ? (
          userData.is_admin ? (
            <>
              <Route path="/home" element={<Home />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/events" element={<Events />} />
              <Route path="/alerts" element={<Alerts />} />
              <Route path="/lists" element={<WhiteList />} />
              <Route path="/tasks" element={<Tasks />} />
              <Route path="/analytics" element={<Analytics />} />
              <Route path="/demo" element={<Demo />} />
              <Route path="/users" element={<Users />} />
              <Route path="/login" element={<Login />} />
              <Route index path="/" element={<Landing />} />
              <Route path="*" element={<NotFound />} />
            </>
          ) : (
            <>
              <Route path="/home" element={<Home />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/events" element={<Events />} />
              <Route path="/alerts" element={<Alerts />} />
              <Route path="/login" element={<Login />} />
              <Route index path="/" element={<Landing />} />
              <Route path="*" element={<NotFound />} />
            </>
          )
        ) : (
          <>
            <Route path="/login" element={<Login />} />
            <Route index path="/" element={<Landing />} />
            <Route path="*" element={<NotFound />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
};

export default App;
