import React, { useContext, useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import moment from "moment";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";

import { TimeLineChart, UsersMap } from "../atoms";
import useResource from "../hooks/useResource";
import { AuthContext } from "../context/auth-context";
import { useUsers } from "../hooks/useUsers";

const UserAnalytics = () => {
  const auth = useContext(AuthContext);
  const { token } = auth;
  const [getReport, isLoading, error, clearError] = useResource();
  const [data, setData] = useState();
  const [mapData, setMapData] = useState();
  const [user, setUser] = useState("");
  const [interval, setInterval] = useState("Last 24 hours");
  const formatDate = (date) => date.format("YYYY-MM-DD");
  // Substracts 10h(?) to account for local time
  const formatTime = (time) => time.format("x") - 36000000;
  const today = formatDate(moment().add(1, "days"));
  const {
    users: userList,
    error: userError,
    isLoading: userListIsLoading,
  } = useUsers(token);

  useEffect(() => {
    fetchMapData(formatDate(moment().subtract(1, "days")), today);
  }, []);

  const handleIntervalChange = (event) => {
    setInterval(event.target.value);
  };

  const handleUserChange = (event) => {
    setUser(event.target.value);
  };

  const handleClick = () => {
    let startDate;
    switch (interval) {
      case "Last 24 hours":
        startDate = moment().subtract(1, "days");
        break;
      case "Last week":
        startDate = moment().subtract(7, "days");
        break;
      case "Last month":
        startDate = moment().subtract(1, "month");
        break;
      default:
        break;
    }
    interval !== "All time"
      ? fetchData(formatDate(startDate), today)
      : fetchData("1990-08-08", today);
  };

  const computeSearchEvents = (x, f) => {
    const test = [];
    const computedObj = x.reduce(
      (a, b, i) => ((a[f(b, i, x)] ||= []).push(b), a),
      {}
    );
    for (const [key, value] of Object.entries(computedObj)) {
      test.push({
        name: key,
        data: value.map((item) => {
          return {
            x: "Events",
            y: [
              formatTime(moment(item.created_at)),
              formatTime(moment(item.created_at).add(1, "minutes")),
            ],
          };
        }),
      });
    }

    return test;
  };

  const fetchData = async (startDate, endDate) => {
    try {
      const response = await getReport(
        process.env.REACT_APP_API_URL +
          `/reports/users/${user}?from_date=${startDate}&to_date=${endDate}`,
        {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`,
        }
      );
      setData(computeSearchEvents(response, (event) => event.action));
    } catch (err) {
      console.log(err);
    }
  };

  const fetchMapData = async (startDate, endDate) => {
    try {
      const response = await getReport(
        process.env.REACT_APP_API_URL +
          `/reports/users/${user}?from_date=${startDate}&to_date=${endDate}`,
        {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`,
        }
      );
      setMapData(response);
    } catch (err) {
      console.log(err);
    }
  };

  if (isLoading || userListIsLoading) {
    return (
      <Backdrop
        sx={{
          color: "#1976d2",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (error || userError) {
    return (
      <Typography
        variant="subtitle2"
        align="center"
        sx={{
          color: "red",
        }}
      >
        Error. {error?.details[0].msg || error?.details || userError}.
      </Typography>
    );
  }

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Users Locations
      </Typography>
      <Box
        sx={{
          marginTop: 2,
          marginBottom: 4,
        }}
      >
        <UsersMap data={mapData} />
      </Box>
      <Typography variant="h6" gutterBottom>
        Users Activity
      </Typography>
      <Box sx={{ display: "flex", gap: 2 }} mb={2}>
        <FormControl sx={{ minWidth: 150 }}>
          <InputLabel id="select-user">User</InputLabel>
          <Select
            labelId="select-user-label"
            id="select-user"
            value={user}
            label="User"
            onChange={handleUserChange}
          >
            {userList &&
              userList.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.username}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 150 }}>
          <InputLabel id="select-time">Interval</InputLabel>
          <Select
            labelId="select-time-label"
            id="select-time"
            value={interval}
            label="Interval"
            onChange={handleIntervalChange}
          >
            <MenuItem value={"Last 24 hours"}>Last 24 hours</MenuItem>
            <MenuItem value={"Last week"}>Last week</MenuItem>
            <MenuItem value={"Last month"}>Last month</MenuItem>
            <MenuItem value={"All time"}>All time</MenuItem>
          </Select>
        </FormControl>
        <Button variant="contained" onClick={handleClick} disabled={!user}>
          Generate Report
        </Button>
      </Box>
      <TimeLineChart data={data} />
    </>
  );
};

export { UserAnalytics };
