import React, { useEffect, useContext, useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import moment from "moment";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import { BarChart } from "../atoms";
import useResource from "../hooks/useResource";
import { AuthContext } from "../context/auth-context";

const GlobalAnalytics = () => {
  const auth = useContext(AuthContext);
  const { token } = auth;
  const [getReport, isLoading, error, clearError] = useResource();
  const [data, setData] = useState();
  const [interval, setInterval] = useState("Last 24 hours");
  const formatDate = (date) => date.format("YYYY-MM-DD");
  const today = formatDate(moment().add(1, "days"));

  useEffect(() => {
    fetchData(formatDate(moment().subtract(1, "days")), today);
  }, []);

  const computeSearchEvents = (obj) => {
    const found = [];
    const notFound = [];
    const users = [];

    for (const [key, value] of Object.entries(obj)) {
      found.push(value.found);
      notFound.push(value.not_found);
      users.push(key);
    }
    return { users, found, notFound };
  };

  const handleIntervalChange = (event) => {
    setInterval(event.target.value);
  };

  const handleClick = () => {
    let startDate;
    switch (interval) {
      case "Last 24 hours":
        startDate = moment().subtract(1, "days");
        break;
      case "Last week":
        startDate = moment().subtract(7, "days");
        break;
      case "Last month":
        startDate = moment().subtract(1, "month");
        break;
      default:
        break;
    }
    interval !== "All time"
      ? fetchData(formatDate(startDate), today)
      : fetchData("1990-08-08", today);
  };

  const fetchData = async (startDate, endDate) => {
    try {
      const response = await getReport(
        process.env.REACT_APP_API_URL +
          `/reports/global?from_date=${startDate}&to_date=${endDate}`,
        {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`,
        }
      );
      setData(response);
    } catch (err) {
      console.log(err);
    }
  };

  if (!data || isLoading) {
    return (
      <Backdrop
        sx={{
          color: "#1976d2",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (error) {
    return (
      <Typography
        variant="subtitle2"
        align="center"
        sx={{
          color: "red",
        }}
      >
        Error. {error?.details[0].msg || error?.details}.
      </Typography>
    );
  }

  return (
    <>
      <Box sx={{ display: "flex", gap: 2 }} mb={2}>
        <FormControl sx={{ minWidth: 150 }}>
          <InputLabel id="select-time">Interval</InputLabel>
          <Select
            labelId="select-time-label"
            id="select-time"
            value={interval}
            label="Interval"
            onChange={handleIntervalChange}
          >
            <MenuItem value={"Last 24 hours"}>Last 24 hours</MenuItem>
            <MenuItem value={"Last week"}>Last week</MenuItem>
            <MenuItem value={"Last month"}>Last month</MenuItem>
            <MenuItem value={"All time"}>All time</MenuItem>
          </Select>
        </FormControl>
        <Button variant="contained" onClick={handleClick}>
          Generate Report
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <BarChart
          data={{
            options: {
              title: {
                text: `Event Type - ${interval}`,
              },
              chart: {
                id: "basic-bar",
              },
              xaxis: {
                categories: Object.keys(data.events_by_type),
              },
            },
            series: [
              {
                name: "series-1",
                data: Object.values(data.events_by_type),
              },
            ],
          }}
          width={1000}
          height={400}
        />
        <BarChart
          data={{
            options: {
              title: {
                text: `Search Events Per User - ${interval}`,
              },
              chart: {
                id: "basic-bar",
              },
              xaxis: {
                categories: computeSearchEvents(data.searches_by_user).users,
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                },
              },
            },
            series: [
              {
                name: "Not Found",
                data: computeSearchEvents(data.searches_by_user).notFound,
              },
              {
                name: "Found",
                data: computeSearchEvents(data.searches_by_user).found,
              },
            ],
          }}
        />
        <BarChart
          data={{
            options: {
              title: {
                text: `Faces per List - ${interval}`,
              },
              chart: {
                id: "basic-bar",
              },
              xaxis: {
                categories: Object.keys(data.faces_in_lists_count),
              },
            },
            series: [
              {
                name: "series-1",
                data: Object.values(data.faces_in_lists_count),
              },
            ],
          }}
        />
      </Box>
    </>
  );
};

export { GlobalAnalytics };
