import React, { useState, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { Navbar } from "../organisms";

const ContentTemplate = ({ children, label, error, isLoading = false }) => {
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (error) setShowAlert(true);
  }, [error]);

  const handleClose = () => {
    setShowAlert(false);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth={false} disableGutters={true}>
        <Navbar label={label}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {error && showAlert && (
              <Stack sx={{ width: "100%" }} spacing={2} mb={2}>
                <Alert severity="error" onClose={handleClose}>
                  Error: {error}
                </Alert>
              </Stack>
            )}
            <Backdrop
              sx={{
                color: "#1976d2",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={isLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            {children}
          </Box>
        </Navbar>
      </Container>
    </React.Fragment>
  );
};

export { ContentTemplate };
