import React, { createContext, useContext } from "react";

import { useLists } from "../hooks/useLists";
import { AuthContext } from "./auth-context";

export const ListsContext = createContext({
  lists: [],
  isLoading: false,
  error: null,
  mutate: () => {},
});

export const ListsContextProvider = ({ children }) => {
  const auth = useContext(AuthContext);
  const { token } = auth;
  const { lists, isLoading, error, mutate } = useLists(token);

  return (
    <ListsContext.Provider
      value={{
        lists,
        isLoading,
        error,
        mutate,
      }}
    >
      {children}
    </ListsContext.Provider>
  );
};
