import React, { useState, useContext } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import useResource from "../hooks/useResource";
import { UserForm } from "./UserForm";
import { Confirmation } from "../atoms";
import { AuthContext } from "../context/auth-context";
import { useUsers } from "../hooks/useUsers";
import { DeviceTable } from "./DeviceTable";
import { DeviceForm } from "./DeviceForm";

function Row({ row, deleteRow, editRow }) {
  const [open, setOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [openDevice, setOpenDevice] = useState(false);
  const [openEditDevice, setOpenEditDevice] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleCloseDevice = () => {
    setOpenDevice(false);
  };

  const handleOpenEditDevice = () => {
    setOpenEditDevice(true);
  };

  const handleCloseEditDevice = () => {
    setOpenEditDevice(false);
  };

  return (
    <>
      <DeviceForm
        open={openDevice}
        handleClose={handleCloseDevice}
        item={row}
      />
      <DeviceForm
        edit
        open={openEditDevice}
        handleClose={handleCloseEditDevice}
        item={row}
      />
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.id}
        </TableCell>
        <TableCell>{row.username}</TableCell>
        <TableCell>{isVisible ? row.password : "*******"}</TableCell>
        <TableCell align="right">
          <Tooltip title={isVisible ? "Hide" : "Show"}>
            <IconButton onClick={toggleVisibility}>
              {isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton onClick={() => editRow(row)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={() => deleteRow(row)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Device
              </Typography>
              {row.device_access_token ? (
                <DeviceTable data={row} handleOpen={handleOpenEditDevice} />
              ) : (
                <>
                  <Typography sx={{ marginBottom: "20px" }}>
                    There are no devices associated to this user
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={() => setOpenDevice(true)}
                  >
                    Add device
                  </Button>
                </>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const UsersTable = ({ data }) => {
  const auth = useContext(AuthContext);
  const { token } = auth;
  const { users, mutate } = useUsers(token);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [activeItem, setActiveItem] = useState();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [removeList, isDeleting, error, clearError] = useResource();

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const openEditDialog = (item) => {
    setActiveItem(item);
    setEdit(true);
  };

  const closeEditDialog = () => {
    setEdit(false);
    setActiveItem();
  };

  const openConfirmationDialog = (item) => {
    setActiveItem(item);
    setOpenConfirmation(true);
  };

  const closeConfirmationDialog = () => {
    setOpenConfirmation(false);
    setActiveItem();
    clearError();
  };

  const deleteEntry = async () => {
    try {
      await removeList(
        `${process.env.REACT_APP_API_URL}/users/${activeItem.id}`,
        {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`,
        },
        "DELETE"
      );
      mutate(users.filter((item) => item.id !== activeItem.id));
      closeConfirmationDialog(false);
    } catch (err) {
      console.log(err);
    }
  };

  if (!!data && data.length > 0) {
    return (
      <>
        <UserForm open={open} handleClose={closeDialog} />
        <UserForm
          edit
          item={activeItem}
          open={edit}
          handleClose={closeEditDialog}
        />
        <Confirmation
          open={openConfirmation}
          action={deleteEntry}
          handleClose={closeConfirmationDialog}
          title="Delete user"
          text="Are you sure you want to delete this user?"
          error={error && JSON.stringify(error)}
          isLoading={isDeleting}
        />
        <Box sx={{ pb: 2 }}>
          <Button variant="contained" onClick={openDialog}>
            Create new user
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>ID</TableCell>
                <TableCell>Username</TableCell>
                <TableCell sx={{ width: "150px" }}>Password</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!!data &&
                data.map((row) => (
                  <Row
                    key={row.id}
                    row={row}
                    deleteRow={openConfirmationDialog}
                    editRow={openEditDialog}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }

  return (
    <>
      <UserForm open={open} handleClose={closeDialog} />
      <Box sx={{ pb: 2 }}>
        <Button variant="contained" onClick={openDialog}>
          Create new user
        </Button>
      </Box>
      <Typography>No users have been created</Typography>
    </>
  );
};

export { UsersTable };
