import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import MapPicker from "react-google-map-picker";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

const DefaultLocation = { lat: 4.662934894758974, lng: -74.09101573088975 };
const DefaultZoom = 11;

const LocationPicker = ({ location, handleChange, data }) => {
  const [zoom, setZoom] = useState(DefaultZoom);

  function handleChangeZoom(newZoom) {
    setZoom(newZoom);
  }

  return (
    <>
      <Typography variant="subtitle2">Select Location</Typography>
      <MapPicker
        defaultLocation={DefaultLocation}
        zoom={zoom}
        mapTypeId="roadmap"
        style={{ height: "300px", width: "500px" }}
        onChangeLocation={handleChange}
        onChangeZoom={handleChangeZoom}
        apiKey={process.env.REACT_APP_MAPS_KEY}
      />
      <Stack direction="row" spacing={2}>
        <TextField
          sx={{ width: "50%" }}
          id="lat-input"
          label="Latitude"
          variant="outlined"
          value={location.lat}
          disabled
          type="text"
        />
        <TextField
          sx={{ width: "50%" }}
          id="lng-input"
          label="Longitude"
          variant="outlined"
          value={location.lng}
          disabled
          type="text"
        />
      </Stack>
    </>
  );
};

export { LocationPicker };
