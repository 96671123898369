import React, { useState, useContext } from "react";
import { default as MTable } from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import { Confirmation } from "../atoms";
import useResource from "../hooks/useResource";
import { useFaces } from "../hooks/useFaces";
import { SubjectForm } from "./SubjectForm";
import { AuthContext } from "../context/auth-context";

const FacesTable = ({ name }) => {
  const [open, setOpen] = useState(false);
  const [activeItem, setActiveItem] = useState();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [removeList, isDeleting, error, clearError] = useResource();
  const auth = useContext(AuthContext);
  const { token } = auth;
  const { faces, mutate, isLoading } = useFaces(name, token);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = (value) => {
    setOpen(false);
  };

  const openConfirmationDialog = (item) => {
    setActiveItem(item);
    setOpenConfirmation(true);
  };

  const closeConfirmationDialog = () => {
    setOpenConfirmation(false);
    setActiveItem();
    clearError();
  };

  const deleteEntry = async () => {
    try {
      await removeList(
        `${process.env.REACT_APP_API_URL}/lists/${name}/faces/${activeItem}`,
        {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`,
        },
        "DELETE"
      );
      mutate(faces.filter((item) => item !== activeItem));
      closeConfirmationDialog(false);
    } catch (err) {
      console.log(err);
    }
  };

  const addFaceToList = (face) => {
    mutate(faces.concat(face));
  };

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!!faces && faces.length > 0) {
    return (
      <>
        <SubjectForm
          open={open}
          handleClose={closeDialog}
          list={name}
          mutate={addFaceToList}
        />
        <Box sx={{ pb: 2 }}>
          <Button variant="contained" onClick={openDialog}>
            Create new entry
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Confirmation
            open={openConfirmation}
            action={deleteEntry}
            handleClose={closeConfirmationDialog}
            title="Delete entry"
            text="Are you sure you want to delete this entry?"
            error={error}
            isLoading={isDeleting}
          />
          <MTable aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {faces.map((item, index) => (
                <TableRow
                  key={item}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {item}
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title="Delete">
                      <IconButton onClick={() => openConfirmationDialog(item)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </MTable>
        </TableContainer>
      </>
    );
  }

  return (
    <>
      <SubjectForm
        open={open}
        handleClose={closeDialog}
        list={name}
        mutate={addFaceToList}
      />
      <Box sx={{ pb: 2 }}>
        <Button variant="contained" onClick={openDialog}>
          Create new entry
        </Button>
      </Box>
      <Typography>No data available</Typography>
    </>
  );
};

export { FacesTable };
