import React, { useState, useContext } from "react";
import { default as MTable } from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { Confirmation } from "../atoms";
import { DialogForm } from "./DialogForm";
import useResource from "../hooks/useResource";
import { ListsContext } from "../context/lists-context";
import { AuthContext } from "../context/auth-context";

const ListsTable = () => {
  const [open, setOpen] = useState(false);
  const [activeItem, setActiveItem] = useState();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [removeList, isLoading, error, clearError] = useResource();
  const ListsCtxt = useContext(ListsContext);
  const { lists, mutate } = ListsCtxt;
  const auth = useContext(AuthContext);
  const { token } = auth;

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = (value) => {
    setOpen(false);
  };

  const openConfirmationDialog = (item) => {
    setActiveItem(item);
    setOpenConfirmation(true);
  };

  const closeConfirmationDialog = () => {
    setOpenConfirmation(false);
    setActiveItem();
    clearError();
  };

  const deleteEntry = async () => {
    try {
      await removeList(
        process.env.REACT_APP_API_URL + "/lists/" + activeItem,
        {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`,
        },
        "DELETE"
      );
      mutate(lists.filter((item) => item !== activeItem));
      closeConfirmationDialog(false);
    } catch (err) {
      console.log(err);
    }
  };

  if (!!lists) {
    return (
      <>
        <DialogForm open={open} handleClose={closeDialog} />
        <Box sx={{ pb: 2 }}>
          <Button variant="contained" onClick={openDialog}>
            Create new entry
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Confirmation
            open={openConfirmation}
            action={deleteEntry}
            handleClose={closeConfirmationDialog}
            title="Delete entry"
            text="Are you sure you want to delete this entry?"
            error={error}
            isLoading={isLoading}
          />
          <MTable aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lists.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {item}
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title="Delete">
                      <IconButton onClick={() => openConfirmationDialog(item)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </MTable>
        </TableContainer>
      </>
    );
  }

  return (
    <>
      <DialogForm open={open} handleClose={closeDialog} />
      <Box sx={{ pb: 2 }}>
        <Button variant="contained" onClick={openDialog}>
          Create new entry
        </Button>
      </Box>
      <Typography>No data available</Typography>
    </>
  );
};

export { ListsTable };
