import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { lighten, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import clsx from "clsx";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .super-app-theme--user_created, .super-app-theme--list_created, .super-app-theme--face_added_to_list, .super-app-theme--device_created":
    {
      backgroundColor: lighten(theme.palette.success.main, 0.7),
      "&:hover": {
        backgroundColor: lighten(theme.palette.success.main, 0.6),
      },
    },
  "& .super-app-theme--user_updated, .super-app-theme--user_logged_in, .super-app-theme--device_token_updated, .super-app-theme--device_updated":
    {
      backgroundColor: lighten(theme.palette.info.main, 0.7),
      "&:hover": {
        backgroundColor: lighten(theme.palette.info.main, 0.6),
      },
    },
  "& .super-app-theme--user_deleted, .super-app-theme--face_removed_from_list, .super-app-theme--list_deleted":
    {
      backgroundColor: lighten(theme.palette.error.main, 0.7),
      "&:hover": {
        backgroundColor: lighten(theme.palette.error.main, 0.6),
      },
    },
  "& .super-app-theme--face_searched_in_list": {
    backgroundColor: lighten(theme.palette.warning.main, 0.7),
    "&:hover": {
      backgroundColor: lighten(theme.palette.warning.main, 0.6),
    },
  },
}));

const HEADERS = [
  {
    field: "created_at",
    headerName: "Date",
    valueGetter: (params) => formatDate(params.value),
    width: "160",
  },
  { field: "username", headerName: "Username", width: "200" },
  { field: "organization", headerName: "Organization" },
  {
    field: "is_admin",
    headerName: "Admin",
    width: "60",
    renderCell: (params) => (params.value ? <CheckIcon /> : <CloseIcon />),
  },
  {
    field: "is_device",
    headerName: "Device",
    width: "60",
    renderCell: (params) => (params.value ? <CheckIcon /> : <CloseIcon />),
  },
  { field: "action", headerName: "Event Type", width: "160" },
  {
    field: "details",
    headerName: "Event Details",
    width: "500",
    valueGetter: (params) => formatDetails(params.row),
    cellClassName: (params) => {
      const isFound = params.row.found;
      return clsx("super-app", {
        found: isFound,
        notFound: !isFound && params.row.action === "face_searched_in_list",
      });
    },
  },
];

const formatDate = (date) => {
  const dateObj = new Date(date + "Z");
  return dateObj.toLocaleDateString(undefined, {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });
};

const formatDetails = (details) => {
  const action = details.action;
  let formatedDetails;

  switch (action) {
    case "user_created":
    case "user_deleted":
    case "user_updated":
    case "device_token_updated":
    case "device_updated":
    case "device_created":
      formatedDetails = `Username: ${details.username_target}`;
      break;
    case "list_created":
    case "list_deleted":
      formatedDetails = `List name: ${details.list_name}`;
      break;
    case "face_searched_in_list":
      formatedDetails = details.found
        ? `Found: ${details.found}, FaceId: ${details.face_id}, Confidence: ${details.confidence}, List name: ${details.list_name}`
        : `Found: False, List name: ${details.list_name}`;
      break;
    case "face_removed_from_list":
    case "face_added_to_list":
      formatedDetails = `FaceId: ${details.face_id}, List name: ${details.list_name}`;
      break;
    default:
      formatedDetails = "-";
  }

  return formatedDetails;
};

const EventsTable = ({ data }) => {
  if (!data.length) {
    return <Typography>No events available</Typography>;
  }
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        "& .super-app.found": {
          color: "#4caf50",
        },
        "& .super-app.notFound": {
          color: "#ef5350",
        },
      }}
    >
      <StyledDataGrid
        rows={data}
        columns={HEADERS}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 50]}
        disableRowSelectionOnClick={true}
        getRowClassName={(params) => `super-app-theme--${params.row.action}`}
      />
    </Box>
  );
};

export { EventsTable };
