import React from "react";
import Paper from "@mui/material/Paper";
import GoogleMapReact from "google-map-react";
import RoomIcon from "@mui/icons-material/Room";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";

const MapMarker = ({ text, $hover }) => {
  const data = text.founds || {
    autorizados: 10,
    buscados: 2,
    sospechosos: 3,
  };

  return (
    <>
      <RoomIcon color="secondary" fontSize="large" />
      {$hover && (
        <Paper
          elevation={3}
          sx={{
            padding: 2,
            marginBottom: 2,
            width: 250,
          }}
        >
          <Typography variant="subtitle2" gutterBottom>
            {text.username}
          </Typography>
          <>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                marginBottom: 10,
              }}
            >
              <div
                style={{
                  backgroundColor: "#00c853",
                  height: 12,
                  flex: data.autorizados,
                }}
              />
              <div
                style={{
                  backgroundColor: "#ffd600",
                  height: 12,
                  flex: data.sospechosos,
                }}
              />
              <div
                style={{
                  backgroundColor: "red",
                  height: 12,
                  flex: data.buscados,
                }}
              />
            </div>
            <Stack direction={"row"} alignItems="center" gap={1}>
              <div
                style={{
                  backgroundColor: "#00c853",
                  height: 10,
                  width: 10,
                  borderRadius: 10,
                }}
              />
              <Typography variant="subtitle2">
                Authorized: {data.autorizados}
              </Typography>
            </Stack>
            <Stack direction={"row"} alignItems="center" gap={1}>
              <div
                style={{
                  backgroundColor: "#ffd600",
                  height: 10,
                  width: 10,
                  borderRadius: 10,
                }}
              />
              <Typography variant="subtitle2">
                Wanted: {data.buscados}
              </Typography>
            </Stack>
            <Stack direction={"row"} alignItems="center" gap={1}>
              <div
                style={{
                  backgroundColor: "red",
                  height: 10,
                  width: 10,
                  borderRadius: 10,
                }}
              />
              <Typography variant="subtitle2">
                Suspicious: {data.sospechosos}
              </Typography>
            </Stack>
          </>
        </Paper>
      )}
    </>
  );
};

const UsersMap = ({ data }) => {
  const defaultProps = {
    center: {
      lat: 4.693181382908399,
      lng: -74.03243278267941,
    },
    zoom: 16,
  };

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 2,
        width: 1040,
        height: 500,
        marginBottom: 2,
      }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_MAPS_KEY }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        {data &&
          data
            .filter((item) => item.lat)
            .map((item) => {
              return (
                <MapMarker
                  key={item.id}
                  lat={item.lat}
                  lng={item.lng}
                  text={item}
                />
              );
            })}
      </GoogleMapReact>
    </Paper>
  );
};

export { UsersMap };
