import React, { useContext } from "react";
import Typography from "@mui/material/Typography";

import { ContentTemplate } from "../atoms";
import { AuthContext } from "../context/auth-context";

const Home = () => {
  const auth = useContext(AuthContext);
  const { username, organization, is_admin } = auth.userData;

  return (
    <ContentTemplate label="Home">
      <Typography variant="h6" gutterBottom>
        Username
      </Typography>
      <Typography variant="body1" gutterBottom>
        {username}
      </Typography>
      <Typography variant="h6" gutterBottom>
        Organization
      </Typography>
      <Typography variant="body1" gutterBottom>
        {organization}
      </Typography>
      <Typography variant="h6" gutterBottom>
        Administrator
      </Typography>
      <Typography variant="body1" gutterBottom>
        {is_admin ? "True" : "False"}
      </Typography>
    </ContentTemplate>
  );
};

export { Home };
