import React, { useContext } from "react";
import { default as MDialog } from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { Button } from "../atoms";
import useResource from "../hooks/useResource";
import { AuthContext } from "../context/auth-context";
import { useUsers } from "../hooks/useUsers";

const UserForm = ({ open, item = {}, handleClose, edit = false }) => {
  const auth = useContext(AuthContext);
  const { token } = auth;
  const { users, mutate } = useUsers(token);
  const [createNewUser, isLoading, error, clearError] = useResource();

  const onConfirm = async (e) => {
    e.preventDefault();
    clearError();
    const formData = new FormData(e.target);
    const formJson = Object.fromEntries(formData.entries());
    const { username, password } = formJson;

    try {
      if (edit) {
        await createNewUser(
          process.env.REACT_APP_API_URL + "/users/" + item.id,
          {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: `Bearer ${token}`,
          },
          "PUT",
          { password }
        );
        const user = users.find((element) => element.id === item.id);
        mutate([...users, ((user.password = password), (user.key = password))]);
      } else {
        const response = await createNewUser(
          process.env.REACT_APP_API_URL + "/users/",
          {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: `Bearer ${token}`,
          },
          "POST",
          { username }
        );
        mutate(users.concat(response));
      }
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };

  const handleBtnClose = () => {
    handleClose();
    clearError();
  };

  return (
    <MDialog
      onClose={handleClose}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{edit ? "Edit user" : "Create new user"}</DialogTitle>
      {error && (
        <Typography
          variant="subtitle2"
          align="center"
          sx={{
            color: "red",
          }}
        >
          Error: {JSON.stringify(error)}
        </Typography>
      )}
      <DialogContent>
        <Stack
          component="form"
          sx={{
            width: "25ch",
            p: 1,
          }}
          spacing={2}
          autoComplete="off"
          onSubmit={onConfirm}
        >
          {edit ? (
            <>
              <TextField
                name="username"
                id="name-input"
                label="Username"
                type="email"
                defaultValue={item.username}
                disabled={true}
              />
              <TextField
                name="password"
                id="password-input"
                label="Password"
                type="text"
                inputProps={{ minLength: 10 }}
                required
              />
            </>
          ) : (
            <TextField
              name="username"
              id="name-input"
              label="Username"
              type="email"
              required
            />
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              marginTop: "1.5rem",
            }}
          >
            <Button label="cancel" action={handleBtnClose} variant="text" />
            {edit ? (
              <Button
                label="Update"
                variant="text"
                isLoading={isLoading}
                type="submit"
              />
            ) : (
              <Button
                label="Create"
                variant="text"
                isLoading={isLoading}
                type="submit"
              />
            )}
          </Box>
        </Stack>
      </DialogContent>
    </MDialog>
  );
};

export { UserForm };
