import React, { useState, useContext } from "react";
import { default as MDialog } from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { Button } from "../atoms";
import useResource from "../hooks/useResource";
import { ListsContext } from "../context/lists-context";
import { AuthContext } from "../context/auth-context";

const DialogForm = ({ open, handleClose }) => {
  const [name, setName] = useState("");
  const [createNewList, isLoading, error, clearError] = useResource();
  const ListsCtxt = useContext(ListsContext);
  const { lists, mutate } = ListsCtxt;
  const auth = useContext(AuthContext);
  const { token } = auth;

  const onConfirm = async () => {
    clearError();
    try {
      await createNewList(
        process.env.REACT_APP_API_URL + "/lists/",
        {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`,
        },
        "POST",
        { name }
      );
      mutate(lists.concat(name));
      handleClose();
    } catch (err) {
      console.log(err);
    }
    setName("");
  };

  const handleBtnClose = () => {
    handleClose();
    setName("");
    clearError();
  };

  return (
    <MDialog
      onClose={handleClose}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>Create new list</DialogTitle>
      {error && (
        <Typography
          variant="subtitle2"
          align="center"
          sx={{
            color: "red",
          }}
        >
          Error: {error.message}.
        </Typography>
      )}
      <DialogContent>
        <Stack
          component="form"
          sx={{
            width: "25ch",
            p: 1,
          }}
          spacing={2}
          autoComplete="off"
        >
          <TextField
            id="name-input"
            label="Name"
            type="text"
            value={name}
            onChange={(event) => {
              setName(event.target.value);
            }}
            required
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button label="cancel" action={handleBtnClose} variant="text" />
        <Button
          action={onConfirm}
          disabled={!name}
          label="Create"
          variant="text"
          isLoading={isLoading}
        />
      </DialogActions>
    </MDialog>
  );
};

export { DialogForm };
