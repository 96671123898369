import React from "react";

import { PageTemplate } from "../atoms";
import { LoginForm } from "../organisms";

const Login = () => {
  return (
    <PageTemplate center>
      <LoginForm />
    </PageTemplate>
  );
};

export { Login };
