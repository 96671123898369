import React from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";

const LinkBtn = ({ children, to }) => {
  return (
    <Button variant="contained" component={Link} to={to}>
      {children}
    </Button>
  );
};

export { LinkBtn };
