import React from "react";
import Chart from "react-apexcharts";
import Paper from "@mui/material/Paper";

const BarChart = ({ data, width = "500", height }) => {
  return (
    <Paper
      elevation={3}
      sx={{
        padding: 2,
        maxWidth: "max-content",
      }}
    >
      <Chart
        options={data.options}
        series={data.series}
        type="bar"
        width={width}
        height={height}
      />
    </Paper>
  );
};

export { BarChart };
