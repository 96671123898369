import useSWR from "swr";

const URL = process.env.REACT_APP_API_URL + "/events/";

const fetcher = async (url, token) => {
  const res = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  const json = await res.json();
  return json;
};

export const useEvents = (token) => {
  const { data, error, mutate } = useSWR([URL, token], fetcher);

  return {
    events: data,
    isLoading: !error && !data,
    error: data?.detail,
    mutate,
  };
};
