import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";

const Button = ({
  label,
  action,
  disabled = false,
  isLoading = false,
  type = "button",
  variant = "contained",
}) => {
  return (
    <LoadingButton
      loading={isLoading}
      variant={variant}
      type={type}
      onClick={action}
      disabled={disabled}
    >
      {label}
    </LoadingButton>
  );
};

export { Button };
