import React, { useState, useRef, useContext } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { ContentTemplate, Select, Button } from "../atoms";
import { useLists } from "../hooks/useLists";
import useResource from "../hooks/useResource";
import { AuthContext } from "../context/auth-context";

const Demo = () => {
  const auth = useContext(AuthContext);
  const { token } = auth;
  const { lists, error: listError } = useLists(token);
  const [base64Image, setBase64Image] = useState("");
  const [value, setValue] = useState("");
  const [response, setResponse] = useState();
  const [findFace, isLoadingResults, error, clearError] = useResource();
  const fileInputRef = useRef(null);

  const handleChange = (event) => {
    setResponse(null);
    setValue(event.target.value);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      const base64Image = event.target.result;
      setBase64Image(base64Image);
    };
    fileReader.readAsDataURL(file);
  };

  const handleReset = () => {
    setBase64Image("");
    setValue("");
    setResponse(null);
    clearError();
    if (fileInputRef.current) fileInputRef.current.value = null;
  };

  const handleConfirm = async () => {
    clearError();
    try {
      const apiResponse = await findFace(
        process.env.REACT_APP_API_URL +
          `/lists/${value === "all" ? "search-all" : "search"}`,
        {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`,
        },
        "POST",
        { list_name: value, image: base64Image }
      );
      setResponse(apiResponse);
    } catch (err) {
      console.log(err);
    } finally {
      fileInputRef.current.value = null;
    }
  };

  const Response = ({ data }) => {
    return (
      <>
        <Typography variant="body1" gutterBottom>
          List name: {data.list_name ? data.list_name.toString() : "-"}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Found: {data.found.toString()}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Confidence: {data.confidence ? data.confidence.toString() : "-"}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Face ID: {data.face_id ? data.face_id.toString() : "-"}
        </Typography>
        <br />
      </>
    );
  };

  return (
    <ContentTemplate label="Demo">
      {listError ? (
        <Typography
          variant="h6"
          mt={2}
          align="center"
          sx={{
            color: "red",
          }}
        >
          Error: {JSON.stringify(listError)}
        </Typography>
      ) : (
        <Box
          sx={{
            display: "flex",
            "& > :not(style)": {
              m: 1,
              p: 2,
              width: "50%",
              height: "auto",
            },
          }}
        >
          <Paper elevation={2}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Typography variant="h5" gutterBottom>
                Compare an image against a list or search in all lists
              </Typography>
              <Avatar sx={{ width: 200, height: 200 }} src={base64Image}>
                {base64Image ? null : " "}
              </Avatar>
              <Box>
                <TextField
                  ref={fileInputRef}
                  name="file"
                  id="image-input"
                  type="file"
                  required
                  inputProps={{ accept: "image/png, image/jpeg" }}
                  onChange={handleImageUpload}
                />
              </Box>
              <Select
                label="List"
                data={lists}
                action={handleChange}
                value={value}
              />
              <Box sx={{ display: "flex", gap: 1 }}>
                <Button label="reset" action={handleReset} />
                <Button
                  action={handleConfirm}
                  disabled={!value || !base64Image}
                  label="Find"
                  isLoading={isLoadingResults}
                />
              </Box>
            </Box>
          </Paper>
          <Paper elevation={2}>
            <Typography variant="h5" gutterBottom>
              Results
            </Typography>
            {response ? (
              value === "all" ? (
                <>
                  {response.map((item) => {
                    return <Response data={item} />;
                  })}
                </>
              ) : (
                <Response data={response} />
              )
            ) : (
              <Typography variant="body1" gutterBottom>
                Upload an image, select a list and click on "Find"
              </Typography>
            )}
            {error && (
              <Typography
                variant="body1"
                sx={{
                  color: "red",
                }}
              >
                Error: {error.details}.
              </Typography>
            )}
          </Paper>
        </Box>
      )}
    </ContentTemplate>
  );
};

export { Demo };
