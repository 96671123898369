import React from "react";

import { PageTemplate, LinkBtn } from "../atoms";

const Landing = () => {
  return (
    <PageTemplate center>
      <p>Landing Page</p>
      <LinkBtn to="/login">Enter</LinkBtn>
    </PageTemplate>
  );
};

export { Landing };
