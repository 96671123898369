import React, { useContext } from "react";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CachedIcon from "@mui/icons-material/Cached";

import useResource from "../hooks/useResource";
import { AuthContext } from "../context/auth-context";
import { useUsers } from "../hooks/useUsers";

const DeviceTable = ({ data, handleOpen }) => {
  const auth = useContext(AuthContext);
  const { token } = auth;
  const { users, mutate } = useUsers(token);
  const [refreshToken, isLoading, error, clearError] = useResource();

  const trimToken = (token) => {
    return token.substring(0, 8) + "..." + token.slice(-8);
  };

  const onRefresh = async () => {
    clearError();
    try {
      const response = await refreshToken(
        process.env.REACT_APP_API_URL + `/users/${data.id}/device-access-token`,
        {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`,
        },
        "PUT"
      );
      const user = users.find((element) => element.id === data.id);
      mutate([
        ...users,
        ((user.token = response.device_access_token),
        (user.key = response.device_access_token)),
      ]);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Table size="small" aria-label="purchases">
      <TableHead>
        <TableRow>
          <TableCell>Notifications phone</TableCell>
          <TableCell>Device Access Token</TableCell>
          <TableCell>Device Location</TableCell>
          <TableCell align="right">Device Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>{data.phone}</TableCell>
          <TableCell>{trimToken(data.device_access_token)}</TableCell>
          <TableCell>
            lat: {data.lat}, lng: {data.lng}
          </TableCell>
          <TableCell align="right">
            <Tooltip title="Copy Token">
              <IconButton
                onClick={() =>
                  navigator.clipboard.writeText(data.device_access_token)
                }
              >
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Refresh Token">
              <IconButton onClick={onRefresh}>
                <CachedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton onClick={handleOpen}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export { DeviceTable };
