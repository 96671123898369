import { useState, useCallback } from "react";

const useFormData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const sendRequest = useCallback(async (url, data) => {
    setIsLoading(true);

    return new Promise((resolve, reject) => {
      try {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", url);
        xhr.setRequestHeader(
          "Content-Type",
          "application/x-www-form-urlencoded"
        );
        const formData = new FormData(data);
        const dataString = new URLSearchParams(formData).toString();
        xhr.onload = () => {
          const response = JSON.parse(xhr.responseText);
          if (xhr.status === 200) {
            resolve(response);
          } else {
            setError(JSON.stringify(response.detail));
            reject(new Error("Form submission failed"));
          }
        };
        xhr.send(dataString);
      } catch (error) {
        console.error("An error occurred during form submission:", error);
        reject(error);
      } finally {
        setIsLoading(false);
      }
    });
  }, []);

  const clearError = () => {
    setError(null);
  };

  return [sendRequest, isLoading, error, clearError];
};

export default useFormData;
